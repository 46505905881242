import { useState } from 'react';

import { useAuthStore } from '@northladder/services';

import {
  DynamicActiveAccountsLoginForm,
  DynamicLoginForm,
} from '../../features';
import { LoginLayout } from '../../layouts';

/**
 * -----------------------------------------------------------------------------
 * Root of the login module used to capture user login info, show multi-session
 * sign ins, or prompt for password reset or sign-ups/registrations.
 */
export default function LoginPage() {
  const { allSessionsIndices } = useAuthStore();

  const [isLoginFormVisible, setIsLoginFormVisible] = useState(
    allSessionsIndices?.length <= 0
  );

  const handleShowActiveAccountsForm = () => {
    setIsLoginFormVisible(() => false);
  };

  const handleShowLoginForm = () => {
    setIsLoginFormVisible(() => true);
  };

  return (
    <LoginLayout title="Member Login | Dealer @ NorthLadder">
      {isLoginFormVisible ? (
        <DynamicLoginForm onShowActiveAccounts={handleShowActiveAccountsForm} />
      ) : (
        <DynamicActiveAccountsLoginForm onShowLoginForm={handleShowLoginForm} />
      )}
    </LoginLayout>
  );
}
